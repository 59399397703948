import {
  lazy
} from 'react';

export default {
  home: {
    path: '/',
    isActive: true,
    component: lazy(async () => await import('../views/HomeView')),
    isExact: true,
    isHash: false,
    clientId: 'home',
    displayName: 'Home'
  },
  privacyPolicy: {
    isActive: true,
    path: '/PrivacyPolicy',
    component: lazy(async () => await import('../views/PrivacyPolicyView')),
    isExact: true,
    isHash: false,
    clientId: 'Privacy Policy',
    displayName: ''
  },
  termsOfUse: {
    isActive: true,
    path: '/TermsOfUse',
    component: lazy(async () => await import('../views/TermsOfUseView')),
    isExact: true,
    isHash: false,
    clientId: 'termsOfUse',
    displayName: 'Terms of Use'
  },
  quote: {
    isActive: true,
    path: '/#quote',
    component: null,
    isExact: false,
    isHash: true,
    clientId: 'quote',
    displayName: 'Quote'
  },
  services: {
    isActive: true,
    path: '/#services',
    component: null,
    isExact: false,
    isHash: true,
    clientId: 'services',
    displayName: 'Services'
  },
  portfolio: {
    isActive: true,
    path: '/#portfolio',
    component: null,
    isExact: false,
    isHash: true,
    clientId: 'portfolio',
    displayName: 'Portfolio'
  },
  recommendations: {
    isActive: true,
    path: '/#recommendations',
    component: null,
    isExact: false,
    isHash: true,
    clientId: 'recommendations',
    displayName: 'Recommendations'
  },
  skillsets: {
    isActive: true,
    path: '/#skillsets',
    component: null,
    isExact: false,
    isHash: true,
    clientId: 'skillsets',
    displayName: 'Skillsets'
  },
  pricing: {
    isActive: true,
    path: '/#pricing',
    component: null,
    isExact: false,
    isHash: true,
    clientId: 'pricing',
    displayName: 'Pricing'
  },
  about: {
    isActive: true,
    path: '/#about',
    component: null,
    isExact: false,
    isHash: true,
    clientId: 'about',
    displayName: 'About'
  },
  contact: {
    isActive: true,
    path: '/#contact',
    component: null,
    isExact: false,
    isHash: true,
    clientId: 'contact',
    displayName: 'Contact'
  }
};
