import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const LoadingIcon = props => {
  const {
    outerClassName,
    iconClassName,
    color
  } = props;
  return (
    <div
      className={outerClassName}>
      <i
        className={`${iconClassName} text-${color}`}
      />
    </div>
  );
}

LoadingIcon.propTypes = {
  outerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  color: PropTypes.string
};
LoadingIcon.defaultProps = {
  outerClassName: 'm-5 p-5 h3',
  iconClassName: 'fas fa-circle-notch fa-spin',
  color: 'dark'
};

export default LoadingIcon;
