import React, {
  Suspense
} from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import 'lazysizes';
import routes from './components/Routes';
import './assets/scss/john-shortland-online-web.scss';
import LoadingSpinner from './components/LoadingSpinner';

const activeRouteKeys = Object.keys(routes)
  .filter(key =>
    routes[key].isActive && !routes[key].isHash
  );
ReactDOM.render(
  <Suspense fallback={<LoadingSpinner color="light" />}>
    <BrowserRouter>
      <Switch>
        {
          activeRouteKeys.map(activeRouteKey => {
            const activeRoute = routes[activeRouteKey];
            const {
              path,
              component,
              isExact
            } = activeRoute;
            return (
              <Route
                key={activeRouteKey}
                path={path}
                component={component}
                exact={isExact}
              />
            );
          })
        }
      </Switch>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);
