import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import LoadingIcon from '../LoadingIcon';

const LoadingSpinner = props => {
  const {
    outerClassName,
    iconOuterClassName,
    iconClassName,
    color
  } = props;
  return (
    <div
      className={outerClassName}>
      <LoadingIcon
        outerClassName={iconOuterClassName}
        iconClassName={iconClassName}
        color={color}
      />
    </div>
  )
};

LoadingSpinner.propTypes = {
  outerClassName: PropTypes.string,
  iconOuterClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  color: PropTypes.string
};
LoadingSpinner.defaultProps = {
  outerClassName: 'p-5 text-center',
  iconOuterClassName: 'p-5 h3',
  iconClassName: 'fas fa-circle-notch fa-spin',
  color: 'dark'
};

export default LoadingSpinner;
